<template>
  <div>
    <Heard />
    <div class="container-fluid searchbox">
      <div class="conth container">
        <el-input
          v-model="searchname"
          style="width: 600px"
          placeholder="请输入岗位或企业名称"
          clearable
        ></el-input>
        <div class="smbox" @click="gosearch">搜索</div>
      </div>
    </div>

    <div class="tips container">
      <div class="industry">
        <div class="ty">行业领域</div>
        <div class="allindustry">
          <p
            :class="{ acttype: hyType == '不限' }"
            @click="(hyType = '不限'), getpostlist()"
          >
            不限
          </p>
          <p
            :class="{ acttype: hyType === item }"
            v-for="item in industry"
            :key="item"
            @click="(hyType = item), (hyTypename = item), getpostlist()"
          >
            {{ item }}
          </p>
        </div>
        <div class="all">全部</div>
      </div>

      <div class="sectipsbox">
        <div class="left">
          <div class="sectips">
            <div class="choose">
              <span> {{ select[0].title }}</span>

              <el-select
                v-model="price"
                placeholder="不限"
                size="mini"
                @change="pricechange"
              >
                <el-option
                  v-for="item in select[0].options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="sectips">
            <div class="choose">
              <span> {{ select[1].title }}</span>

              <el-select
                v-model="jobType"
                placeholder="不限"
                size="mini"
                @change="jobtypechange"
              >
                <el-option
                  v-for="item in select[1].options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="sectips">
            <div class="choose">
              <span> {{ select[2].title }}</span>

              <el-select
                v-model="workExperience"
                placeholder="不限"
                size="mini"
                @change="expchange"
              >
                <el-option
                  v-for="item in select[2].options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="sectips">
            <div class="choose">
              <span> {{ select[3].title }}</span>

              <el-select
                v-model="edu"
                placeholder="不限"
                size="mini"
                @change="educhange"
              >
                <el-option
                  v-for="item in select[3].options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="sectips">
            <div class="choose">
              <span> {{ select[4].title }}</span>
              <el-select
                v-model="scale"
                placeholder="不限"
                size="mini"
                @change="getpostlist()"
              >
                <el-option
                  v-for="item in select[4].options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="rg" @click="cleartips">清空筛选</div>
      </div>
    </div>
    <div class="or container">
      <span :class="{ actspan: type == 1 }" @click="(type = 1), getpostlist()"
        >岗位</span
      >
      <span :class="{ actspan: type == 2 }" @click="(type = 2), getpostlist()"
        >公司</span
      >
    </div>
    <div class="cont container">
      <div
        class="item"
        v-for="item in postlist"
        :key="item.id"
        @click="$router.push(`/postdeil/${item.id}.html`)"
      >
        <div class="top">
          <div class="lt">
            <div class="tit">{{ item.jobName }}</div>
            <div class="des">
              <div class="money">{{ item.umoney }}</div>
              <div class="edu">{{ item.exp }} / {{ item.edu }}</div>
            </div>
          </div>
          <div class="rt">
            <div class="mid">
              <div class="name">{{ item.companyName }}</div>
              <div class="ads">{{ item.address }}</div>
            </div>

            <img
              :src="
                item.logoImgPath
                  ? item.logoImgPath
                  : require('@/assets/image/baseicon.png')
              "
              alt=""
            />
          </div>
        </div>
        <div class="bot">{{ item.createTime.split(" ")[0] }}</div>
      </div>
      <el-empty description="暂无数据" v-if="postlist.length == 0"></el-empty>
    </div>
    <el-pagination
      class="container pagesfoot"
      background
      :page-size="10"
      :pager-count="11"
      layout="prev, pager, next"
      :total="totalCount"
      :current-page.sync="currPage"
      @current-change="pagechange"
    >
    </el-pagination>
    <Footer />
  </div>
</template>

<script>
import Heard from "@/components/Heard.vue";
import Footer from "@/components/Footer.vue";
import { api_queryHyType, api_queryJobList } from "@/apis/apis.js";
export default {
  components: {
    Heard,
    Footer,
  },
  data() {
    return {
      hyTypename: "不限",
      searchname: "",
      industry: [],
      postlist: [],
      totalCount: 1,
      currPage: 1,
      select: [
        {
          title: "期望薪资",
          value1: "1",
          options: [
            {
              value: "不限",
              label: "不限",
            },
            {
              value: "5K以下",
              label: "5K以下",
            },
            {
              value: "5-8K",
              label: "5-8K",
            },
            {
              value: "8-10K",
              label: "8-10K",
            },
            {
              value: "10-15K",
              label: "10-15K",
            },
            {
              value: "15-20K",
              label: "15-20K",
            },
            {
              value: "20-30K",
              label: "20-30K",
            },
            {
              value: "30K+",
              label: "30K+",
            },
          ],
        },
        {
          title: "工作类型",
          value1: "2",
          options: [
            {
              value: "不限",
              label: "不限",
            },
            {
              value: "全职",
              label: "全职",
            },
            {
              value: "兼职",
              label: "兼职",
            },
          ],
        },
        {
          title: "经验要求",
          value1: "3",

          options: [
            {
              value: "不限",
              label: "不限",
            },
            {
              value: "1年以下",
              label: "1年以下",
            },
            {
              value: "1年",
              label: "1年",
            },

            {
              value: "1-3年",
              label: "1-3年",
            },
            {
              value: "3年",
              label: "3年",
            },
            {
              value: "3-5年",
              label: "3-5年",
            },
            {
              value: "5年",
              label: "5年",
            },

            {
              value: "5-10年",
              label: "5-10年",
            },
            {
              value: "10年",
              label: "10年",
            },
            {
              value: "10年以上",
              label: "10年以上",
            },
          ],
        },
        {
          title: "学历要求",
          value1: "4",

          options: [
            {
              value: "不限",
              label: "不限",
            },
            {
              value: "小学",
              label: "小学",
            },
            {
              value: "初中",
              label: "初中",
            },
            {
              value: "高中",
              label: "高中",
            },
            {
              value: "中专",
              label: "中专",
            },
            {
              value: "大专",
              label: "大专",
            },
            {
              value: "本科",
              label: "本科",
            },
            {
              value: "硕士",
              label: "硕士",
            },
            {
              value: "博后",
              label: "博后",
            },
          ],
        },
        {
          title: "公司规模",
          value1: "5",

          options: [
            {
              value: "不限",
              label: "不限",
            },
            {
              value: "10人以下",
              label: "10人以下",
            },

            {
              value: "10-99人",
              label: "10-99人",
            },

            {
              value: "100-499人",
              label: "100-499人",
            },
            {
              value: "500-999人",
              label: "500-999人",
            },
            {
              value: "1000-2999人",
              label: "1000-2999人",
            },
            {
              value: "3000人以上",
              label: "3000人以上",
            },
          ],
        },
      ],

      price: "不限",
      type: 1,
      hyType: "不限",
      jobType: "不限",
      workExperience: "不限",
      edu: "不限",
      minSalary: "",
      maxSalary: "",
      scale: "不限",
      // id: "",
      // totalCount: 1,
      // currPage: 1,
      // news: {},
      // nextNews: {},
      // preNews: {},
      // rightlist: [],
    };
  },

  created() {
    console.log(this.$route.query, "1111");
    this.searchname = this.$route.query.searchname;
    // this.id = await Number(this.$router.params.id);
    this.getpostlist();
    this.getindustrytips();
  },

  methods: {
    getindustrytips() {
      api_queryHyType({}).then((res) => {
        this.industry = res.data.data;
      });
    },
    gosearch() {
      if (this.$route.query) {
        this.$router.push("/post");
      }
      this.getpostlist();
    },
    getpostlist() {
      api_queryJobList({
        name: this.searchname,
        type: this.type, //1:岗位列表 2 企业列表
        userId: 1774,
        hyType: this.hyType == "不限" ? "" : this.hyType, //搜索条件 行业类型
        jobType: this.jobType == "不限" ? "" : this.jobType,
        workExperience:
          this.workExperience == "不限" ? "" : this.workExperience, //搜索条件 工作经验
        edu: this.edu == "不限" ? "" : this.edu, //搜索条件 学历
        scale: this.scale == "不限" ? "" : this.scale, //企业规模
        currPage: this.currPage,
        minSalary: this.minSalary,
        maxSalary: this.maxSalary,
      }).then((res) => {
        console.log(res);
        this.postlist = res.data.data.dataList;
        this.totalCount = res.data.data.totalCount;
      });
    },
    pricechange() {
      let num = this.price.slice(0, this.price.indexOf("K"));
      this.minSalary = "";
      this.maxSalary = "";

      if (num == 5) {
        this.maxSalary = 5;
      } else if (num == 30) {
        this.minSalary = 30;
      }
      if (num.length >= 3) {
        this.minSalary = num.split("-")[0];
        this.maxSalary = num.split("-")[1];
      }

      this.getpostlist();
    },
    jobtypechange() {
      this.getpostlist();
    },
    expchange() {
      this.getpostlist();
    },
    educhange() {
      this.getpostlist();
    },
    cleartips() {
      this.workExperience = "不限";
      this.edu = "不限";
      this.scale = "不限";
      this.price = "不限";
      this.jobType = "不限";
      this.jobType = "不限";
      this.getpostlist();
    },
    pagechange(page) {
      this.currPage = page;
      this.getpostlist();
    },
  },
};
</script>



<style lang="less" scoped>
.container-fluid {
  min-width: 1200px;
}

.container {
  width: 1200px;
  margin: 0 auto;
}
.searchbox {
  height: 200px;
  display: flex;
  align-items: center;
  // background-color: #458dff;
  .conth {
    display: flex;
    align-items: center;
    justify-content: center;
    .smbox {
      margin-left: 10px;
      color: #fff;
      border: 1px solid #fff;
      width: 100px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      background-color: #458dff;
    }
  }
}
.tips {
  .industry {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding-bottom: 16px;
    border-bottom: 1px solid #d5d5d5;

    // height: 60px;
    //overflow: hidden;
    margin-bottom: 20px;
    .ty {
      width: 100px;
    }
    .allindustry {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      color: #7b7b7b;
      p {
        margin-right: 24px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .acttype {
        color: #458dff;
      }
    }
    .all {
      width: 100px;
      cursor: pointer;
    }
  }
  .sectipsbox {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 16px;

    .left {
      display: flex;
      .sectips {
        margin-right: 28px;
        .choose {
          span {
            width: 56px;
            font-size: 14px;
            color: #000000;
            margin-right: 10px;
          }
          .el-select {
            width: 120px;
          }
        }
      }
    }
    .rg {
      width: 100px;
      color: #2a84e0;
      cursor: pointer;
    }
  }
}
.or {
  background: #fafafa;
  height: 44px;
  margin-top: 40px;

  span {
    display: inline-block;
    width: 150px;
    height: 44px;
    text-align: center;
    line-height: 44px;
  }
  .actspan {
    background-color: #fff;
    border-top: 1px solid #458dff;
  }
}
.cont {
  padding: 30px;
  .item {
    width: 100%;
    height: 160px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 28px;
    .top {
      display: flex;
      justify-content: space-between;
      .lt {
        .tit {
          font-size: 18px;
          font-weight: bold;
          color: #000000;
          margin-bottom: 20px;
        }
        .des {
          display: flex;
          align-items: center;
          .money {
            font-size: 22px;
            font-weight: bold;
            color: #dc2e37;
            margin-right: 33px;
          }
          .edu {
            font-size: 14px;
            color: #000000;
          }
        }
      }
      .rt {
        display: flex;
        justify-content: space-between;
        width: 55%;
        .mid {
          .name {
            font-size: 18px;
            font-weight: bold;
            color: #000000;
            margin-bottom: 28px;
          }
        }
        img {
          width: 72px;
          height: 72px;
          border-radius: 6px;
        }
      }
    }
    .bot {
      height: 48px;
      line-height: 48px;
      border-top: 3px solid #ebedf2;
      color: #979fa5;
    }
  }
}
.pagesfoot {
  text-align: center;
  margin-bottom: 80px;
}
</style>